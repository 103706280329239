<template>
  <!-- <div id="submenu">
    <router-link :to="{ name: 'Partners' }" class="list-group-item" :class="{active: menu_list.partner}">Our Partners <i class="fa fa-chevron-right"></i></router-link>
    
    <router-link :to="{ name: 'FeaturedStores' }" class="list-group-item" :class="{active: menu_list.featuredstore}">Featured Stores <i class="fa fa-chevron-right"></i></router-link>
  </div> -->
  <div id="submenu">
    <router-link :to="{ name: 'FeaturedStores' }" class="list-group-item" :class="{active: menu_list.featuredstore}">Featured Partners <i class="fa fa-chevron-right"></i></router-link>
    <router-link :to="{ name: 'Partners' }" class="list-group-item" :class="{active: menu_list.partner}">Featured Brands <i class="fa fa-chevron-right"></i></router-link>
    <router-link :to="{ name: 'LineCard'}" class="list-group-item" :class="{active: menu_list.linecard}">Product Line Card <i class="fa fa-chevron-right"></i></router-link>
    <router-link :to="{ name: 'Webinars'}" class="list-group-item" :class="{active: menu_list.data}">Ma Labs On-Demand Webinars <i class="fa fa-chevron-right"></i></router-link>
    
    
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: [ 'menu' ],
  setup(props) {
    const menu_list = ref({
        partner: false,
        featuredstore: false,
        linecard: false,
        webinars: false
      })
      
    menu_list.value[props.menu] = true
     
    return { menu_list }
  }
}
</script>