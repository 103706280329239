<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-partner menu="linecard"></submenu-partner> 
      
      <div class="submenu-content">

        <h2>Product Line Card</h2>
        <!-- <iframe id="idIframe" src="/media/documents/linecard.pdf" height="1000"
        width="100%" title="Iframe Example"
        frameborder="1">
        
        </iframe> -->
        <!-- just need go to cms upload line card(name should keep the same) -->
        <img src="/media/images/Line_Card.jpg" class="image">
        <img src="/media/images/Line_Card2.jpg" class="image">

      </div> 
    
  </div>
</template>

<script>
import submenuPartner from '../../components/nav/submenuPartner.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref } from "vue"
import { getAPI } from '../../utils/axios-api'
import { BackendDomain } from "../../utils/settings.js";

export default {
  name: 'LineCard',
  components: { submenuPartner, BreadcrumbPage },
  setup() {
    const error = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Partners", link: ""})
    breadcrumb_links.value.push({text: "Product Line Card", link: "FeaturedStores"})

    

    return { error, breadcrumb_links,BackendDomain}

  }
}
</script>

<style scoped>
  #featured_stores {
    text-align: center;
  }
 .image {
    width:100%;
    
  } 
</style>